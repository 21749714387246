import React, { Component } from "react"
import './BlogList.less';
import {graphql} from "gatsby";
import Wrapper from '../components/Wrapper.js';
import PostListItem from '../components/PostListItem';
import { Pagination } from 'antd';
import { navigate } from '@reach/router';
import PageTitle from "../modules/PageTitle";

class BlogList extends Component {

    gotoPage(page){
        if(page===1){
            navigate(`/blog`);
        } else {
            navigate(`/blog/page/${page}`);
        }
    }

    render() {
        const posts = this.props.data.allWordpressPost.edges;
        return (
            <Wrapper
                title="Our blog – team thoughts and industry reflections"
                description="Our blog is full of our latest team thoughts and industry reflections, so there'll also be something new for you to get into. Come read for yourself..."
                canonical={ this.props.pageContext.current === 1 ? '' : '/blog/' }
            >
                <div className="blog-listing">
                    <PageTitle title="Brew blog"/>
                    <span className="intro">Grab a brew and read through</span>
                    {posts && posts.map(({node}) => {
                        return (
                            <PostListItem key={node.id} post={node} />
                        )
                    })}
                </div>
                <Pagination
                    current={this.props.pageContext.current}
                    total={this.props.pageContext.numPosts}
                    pageSize={this.props.pageContext.limit}
                    onChange={this.gotoPage}
                />
            </Wrapper>
        )
    }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allWordpressPost(limit: $limit, skip: $skip, sort: { order: DESC, fields: [date] }){
      edges {
        node {
        id
        slug
        title
        date(formatString: "YYYY-MM-DDTHH:MM:SS")
        humanDate: date(formatString: "DD.MM.YYYY")
        tags {
          id
          name
          slug
        }
        acf {
          reading_time
          excerpt
          image {                                
            localFile {
              childImageSharp {
                fluid(maxHeight: 640, quality: 80) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
 }
`;

export default BlogList


