import React from 'react';
import './style.less';
import Img from 'gatsby-image'
import Link from "../Link";

export default class PostListItem extends React.Component {

    render() {

        return <Link to={`/blog/${this.props.post.slug}/`} className="blog-listing-item">
            <Img style={{height: 320}} fluid={this.props.post.acf.image.localFile.childImageSharp.fluid} />
            <span className="reading-time">{this.props.post.acf.reading_time}</span>
            <span className="block">
                <span className="date">{this.props.post.humanDate}</span>
                <span className="tags">
                    {this.props.post.tags ? this.props.post.tags.map((tag) => {
                        return (
                            <span key={tag.id} className="tag">{tag.name}</span>
                        )
                    }) : null}
                </span>
                <span className="h3"><span>{this.props.post.title}</span></span>
            </span>
        </Link>
    }
}
